/* Globally used classes specifying element rotation */

/* Rotate by 90 degrees */
.rotateBy90 {
  transform: rotate(90deg);
}

/* Rotate by 180 degrees */
.rotateBy180 {
  transform: rotate(180deg);
}

/* Rotate by 270 degrees */
.rotateBy270 {
  transform: rotate(270deg);
}